import React from "react";
import Page from "../../components/Page";
import {lifeQoutes} from "../../data/qoutes";
import image from "../../images/meditate.jpg"
import QoutesPage from "../../components/Citat/QoutesPage";
import Qoute from "../../components/Citat/Qoute";

export default function Citat() {
  const title="De bästa citaten om livet"

  return (
    <>
    <Page
       title={title}
       description="De allra bästa citaten om livet från framgångsrika individer såsom Dalai Lama, Steve Jobs och många fler."
  >
  <QoutesPage title={title}>
      <img src={image} className="qouteImage" alt="citat livet"/>
      <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8 mt-6">{title}</h1>
        <p>Här kommer de allra bästa citaten om livet. Bli motiverad och inspirerad.</p>
        <div className="qoutesList">
        {lifeQoutes.map((q,index) => {
          return <Qoute key={index} text={q.qoute} author={q.author} number={index+1}/>
        })}
        </div>
        
      </QoutesPage>
    </Page>    
    </>
  );
}
